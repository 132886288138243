/* .wave {
  position: relative;
  height: 70px;
  width: 600px;
  background: #e0efe3;
}
.wave:before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 340px;
  height: 80px;
  background-color: white;
  right: -5px;
  top: 40px;
}
.wave:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 300px;
  height: 70px;
  background-color: #e0efe3;
  left: 0;
  top: 27px;
}


.image-wave {
  /* border-bottom-right-radius: 20% 20% ;  */
  /* border-bottom-left-radius: 30% 10%; */
  /* border-radius: 0% 0% -50px -50px; */

  /* border-bottom: 2px solid  black ; */
  /* border-inline-start-width: 5px; */
/* } 

.cutted {
   border-radius: 50% 50% 0% 0% ;
    height: 50px;
    width: 150px;
    background-color: #0000ff00;
    position: relative;
    z-index: 1;
    margin: auto;
    margin-top: -21px;

} */ 

/* 
.wave {
position: relative;
}
.wave:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width:100%;
    height:4.8125rem;
    background:url('./asstes/wave.png');
     -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
    background-repeat: no-repeat;
    
} */
/* 

@media only screen and (max-width: 600px) {
  .resp_div{ transform: translate(0px, -133px);}
  .resp_img{ height:350pt; width:100%; object-fit: fill;}

} */

@media only screen and  (min-width:600px) and (max-width:1024px) {
  .footer-align{
    min-height:'100vh'
  }
}